<!-- eslint-disable -->
<template>
    <div class="tw-flex tw-h-full tw-w-full tw-relative">
        <div id="font-loader"></div>

        <div class="osint--main tw-flex">
            <div class="tw-relative tw-flex tw-flex-col graph--options tw-w-72 tw-pr-2 tw-overflow-x-hidden">
                <div class="tw-py-2 tw-px-1 tw-flex tw-items-center tw-text-base">
                    <span class="tw-text-xs tw-ml-1 tw-font-semibold">FILTERS</span>
                </div>
                <div class="tw-mb-4 tw-pb-4" style="border-bottom: solid 1px rgb(210 210 210)">
                    <div class="tw-text-sm tw-text-gray-500 tw-flex tw-justify-between tw-cursor-pointer" :class="toolType.length > 0 ? ['highlight--filters'] : ''">
                        <div class="tw-mb-2">Tools</div>
                        <div v-if="toolType.length > 0" class="restore--icon tw-text-xs">
                            <span @click="emptyToolTypeFilter">Clear</span>
                        </div>
                    </div>
                    <vue-multiselect v-model="toolType" :options="toolsList" placeholder="Select tool" :searchable="false" :disabled="isToolFilterDisabled || !isGraphReady" :multiple="true" :checkboxes="true" group-values="categories" group-label="group" :group-select="true" :close-on-select="true" :clear-on-select="true" :show-labels="false" :limit="1" :taggable="true" label="id" track-by="id" @select="onToolTypeChange" @input="executeFilter" class="var(--area-brand-color)"></vue-multiselect>

                    <div class="tw-mt-4 tw-text-sm tw-text-gray-500 tw-flex tw-justify-between tw-cursor-pointer" :class="combinedByFilter.length > 0 ? ['highlight--filters'] : ''">
                        <div class="tw-mb-2">Combine</div>
                        <div v-if="combinedByFilter.length > 0" class="restore--icon tw-text-xs">
                            <span @click="emptyCombinedByFilter">Uncombine</span>
                        </div>
                    </div>
                    <vue-multiselect
                        v-model="combinedByFilter"
                        :options="combinedByOptions"
                        placeholder="Select field to combine"
                        :searchable="false"
                        :disabled="!isGraphReady"
                        :multiple="true"
                        :checkboxes="true"
                        group-values="categories"
                        group-label="group"
                        :group-select="true"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :show-labels="false"
                        :limit="2"
                        :taggable="true"
                        label="id"
                        track-by="id"
                        @select="onCombinedByFilterChange"
                        @input="combineNodes"
                        class="var(--area-brand-color)"
                    ></vue-multiselect>
                </div>
            </div>
        </div>
        <div class="tw-flex tw-relative tw-h-full tw-w-full tw-items-center tw-justify-center">
            <neo-loader :loading="!isGraphReady" class="tw-flex tw-mx-4 tw-text-xl" />
            <KlChart v-show="isGraphReady" style="height: 100%; width: 100%" :id="`kl_new_${new Date()}`" containerClass="klchart" :data="keylineData" @kl-ready="klReady" :ready="loadedChart" />
        </div>
    </div>
</template>

<script>
//  eslint-disable
import KeyLines from "keylines";
import KlChart from "@/components/Chart.vue";
import VueMultiselect from "@/components/vue-multiselect";
import KeyLinesEnums from "./graphEnum.json";

import Loader from "@/components/loader";

import {EventBus} from "@/main.js";

export const LINK_COLOR = {
    CONFIRMED: "#000000",
    DEFAULT: "#989898",
};
export const DEFAULT_CONNECTION_COUNT = 3;

const toolsMapper = {
    company: "corporate Records",
    personIdentifier: "person identifier",
    domain: "company domain",
    complyadvantage: "Adverse/PEPs/Sanction",
};

const removeHyphenAndTitleCase = (type) => {
    let text = type.split("-").join(" ");
    text = text.split("_").join(" ");
    if (text.length >= 1) {
        text = text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
        return text;
    }
    return type;
};

const mapActiveToolToCorrectProductName = (tool) => {
    let correctedToolName = tool;
    switch (tool) {
        case "personIdentifier":
            correctedToolName = "person identifier";
            break;
        case "domain":
            correctedToolName = "company domain";
            break;
        case "company":
            correctedToolName = "corporate records";
    }
    let toolName = removeHyphenAndTitleCase(correctedToolName);
    return toolName;
};

export default {
    name: "KlGraphNew",
    components: {
        KlChart,
        VueMultiselect,
        "neo-loader": Loader,
        // CanvasMenu,
        // Loader,
    },
    props: ["graphData", "readOnly", "expandedGraph", "caseId", "loaderProgress"],
    data() {
        return {
            isMainenity: false,
            sourceName: "",
            showCanvas: false,
            keylineData: {},
            keyLinesEnums: KeyLinesEnums,
            listNodeTypes: [],
            filterRender: 0,
            keylineFilterData: {},
            firstGraphData: null,
            graphDataNew: null,
            chart: null,
            graph: null,
            // iconMap: {},
            filterCollpse: true,
            filterApplied: false,
            applyCombined: false,
            applyDepth: false,
            loadingFilter: false,
            loadingDepth: false,
            loadingCombined: false,
            loading: false,
            activeSection: null,
            // toolType: [],
            nodeConnectionCount: DEFAULT_CONNECTION_COUNT,
            noOfHops: 1,
            possibleSearchTypes: [],
            contains_all: false,
            layoutDone: false,
            nodeDegree: 1,
            individualData: null,
            chartData: {
                time: 1000,
                mode: "adaptive",
                easing: "linear",
            },
            type: "png",
            width: 3,
            exportTypes: {
                png: {type: "button", classes: ["btn btn-sm active-btn"]},
                jpeg: {type: "button", classes: ["btn btn-sm"]},
                svg: {type: "button", classes: ["btn btn-sm"]},
                // pdf: { type: 'button', classes:  ['btn btn-sm'], disabled: false },
            },
            extentsLabels: {
                view: "Current view",
                chart: "Whole chart",
            },
            extents: "view",

            isGraphReady: false,
            isChartLoaded: false,
            toolType: [{id: "Adverse/PEPs/Sanction", label: "complyadvantage"}], // Only for the current PoC
            toggleToolData: false,
            // tools: []
            possibleSearchTypes: [],
            graphFilters: [],
            persistToolFilters: ["Complyadvantage"],

            combinedByFilter: [],
            combinedByOptions: [
                {
                    group: "Combined By",
                    categories: [
                        {label: "name", id: "Name"},
                        {label: "date_of_birth", id: "Date of birth"},
                        {label: "address", id: "Address"},
                        {label: "country", id: "Country"},
                    ],
                },
            ],

            additionalComboLinks: [],
        };
    },

    async mounted() {
        this.firstGraphData = this.graphData;
        this.updateKeylineData(this.graphData);
        // this.setSelectedFilter(this.graphData);
    },
    watch: {
        graphData: {
            async handler(newValue, oldValue) {
                if (newValue != oldValue) {
                    if (this.chart) {
                        this.updateKeylineData(newValue);
                        await this.klReady(this.chart);
                    }
                }
            },
            deep: true,
        },
        isChartLoaded(val) {
            console.log("isChartLoaded", val);
            if (val && this.applyCombined) {
                this.applyCombos();
            }
        },
    },
    methods: {
        async stabilizeGraph() {
            this.layoutDone = true;
            await this.arrangeChart();
            this.layoutDone = false;
        },

        loadedChart(newChart) {
            this.chart.current = newChart;
        },

        updateKeylineData(graphData) {
            this.layoutDone = true;
            this.isChartLoaded = false;
            if (graphData) {
                this.keylineData = {type: "LinkChart", items: this.applyIcons(graphData)};

                // only excute on filter and combined
                if (this.chart) {
                    this.chart.load(this.keylineData).then(() => {
                        console.log("chart loaded!");
                        this.isChartLoaded = true;
                    });
                }

                this.arrangeChart();
                this.layoutDone = false;
            }
        },

        getUniqueNodeTypes() {
            const uniqueNodeTypes = new Set();

            for (const item of this.keylineData.items) {
                uniqueNodeTypes.add(item?.d?.node_type);
            }

            return Array.from(uniqueNodeTypes);
        },

        async arrangeChart() {
            if (this.chart) {
                await this.chart.layout("organic", {
                    animate: false,
                    consistent: true,
                    latten: true,
                    spacing: "equal",
                    tightness: 5,
                    time: this.chartData.time,
                    mode: this.chartData.mode,
                    easing: this.chartData.easing,
                });
            }
        },

        // for graph ready use klReady
        async klReady(chart) {
            this.chart = chart;
            this.chart.options({
                drag: {
                    links: false,
                },
                truncateLabels: {maxLength: 15},
                selectedNode: {
                    b: "#111",
                    bw: 5,
                    fbc: "#333",
                    fc: "white",
                    oc: {b: "#222", bw: 5},
                },
                selectedLink: {
                    c: LINK_COLOR.CONFIRMED, // Color for selected link
                    w: 5, // Width for selected link
                },
                //logo: { u: "/images/Logo.png" },
                iconFontFamily: "Font Awesome 5 Free",
                linkEnds: {avoidLabels: false},
                minZoom: 0.02,
                handMode: true,
            });

            this.graph = KeyLines.getGraphEngine();
            // load the raw items in to the graph engine

            await this.graph.load(chart.serialize());
            this.$emit("KLgraphReady");
            // await this.applyTheme();
            this.setUpEventHandlers(); // event apply on graph
            // this.onSelection(); // set up the initial look

            this.isGraphReady = true;
            console.log(this.isGraphReady);
        },

        setUpEventHandlers() {
            this.chart.on("selection-change", this.onSelection);
            this.chart.on("double-click", (item) => {
                const entityData = this.keylineData.items.find((items) => {
                    if (items.id == item.id) {
                        return items.d.unique_identifier_id;
                    }
                });
                // type === "entity" && unique_identifier_id
                console.log("item", item);
                console.log("entityData", entityData);
                if (entityData) {
                    if (entityData.d.source == "Search Engine") {
                        this.sourceName = "internet";
                    } else {
                        this.sourceName = entityData.d.source;
                    }

                    let payload = {
                        source_name: entityData.d.source,
                        unique_identifier_id: entityData.d.unique_identifier_id,
                        case_id: this.caseId,
                    };
                    if (entityData.d.source == "input") {
                        delete payload.source_name;
                        delete payload.unique_identifier_id;
                        payload.query_ids = [`${entityData.d.unique_identifier_id}`];
                        payload.main_entity = true;
                        this.isMainenity = true;
                    }
                    // this.getEntityData(payload,entityData.d.source);
                    this.showCanvas = true;

                    EventBus.$emit("setExpandedCardData", {id: entityData.d.unique_identifier_id});
                }
            });
        },

        getLinkColor(item) {
            if (item.d.relation_type == "confirmed") {
                return LINK_COLOR.CONFIRMED;
            }
            return LINK_COLOR.DEFAULT;
        },

        applyIcons(items) {
            console.log(items);
            const newItems = items?.map((item) => {
                const {node_type, root_node, unique_identifier_id} = item?.d ?? {};
                const iconPostfix = node_type && root_node ? "-green" : "";

                return {
                    ...item,
                    u: `${this.iconMap[node_type + iconPostfix]}` || "",
                    e: unique_identifier_id || root_node ? 2 : 1,
                };
            });

            return newItems;
        },

        async combineNodes() {
            this.layoutDone = true;
            this.loadingCombined = true;
            this.isGraphReady = false;

            // We need to first re-load graph to allow proper combining with multiple criteria
            this.uncombineNodes();
        },

        async applyCombos() {
            const resultCombos = this.combineNodesByType();

            if (!resultCombos.length) return;

            // close all groups before we combine
            this.chart.combo().close([], {animate: false});
            await this.chart.combo().combine(resultCombos, {
                animate: true,
                time: 500,
                select: false,
                arrange: "concentric",
            });
            console.log("Combo finished!");

            await this.arrangeChart();

            this.foregroundSelection([]);

            this.loadingCombined = false;
            this.isGraphReady = true;
            this.layoutDone = false;
            this.applyCombined = false;
        },

        onSelection() {
            const selectedIds = this.chart.selection();

            // filter out any combo items to get only the underlying selection
            const ids = selectedIds; //selectedIds.filter((id) => !this.chart.combo().isCombo(id));

            // remove the combos from the selection
            this.chart.selection(ids);
            // foreground the filtered selection of items and their connections
            this.foregroundSelection(ids);
        },

        foregroundSelection(ids) {
            if (!ids.length) {
                // restore all the elements in the foreground
                this.chart.foreground(() => true, {type: "all"});
                // clear revealed items
                this.chart.combo().reveal([]);

                if (this.additionalComboLinks.length > 0) {
                    this.chart.setProperties(this.additionalComboLinks.map((acl) => ({id: acl, w: 3})));
                    this.additionalComboLinks = [];
                }
            } else {
                const combos = ids.filter((id) => this.chart.combo().isCombo(id));
                if (combos?.length > 0) {
                    return this.selectCombo(ids);
                }

                const neighbours = this.graph.neighbours(ids, {hops: this.noOfHops});
                const foregroundMap = {};
                const linksToReveal = [];
                const propsToUpdate = [];

                neighbours.links.forEach((linkId) => {
                    // build map of neighbouring links to foreground
                    foregroundMap[linkId] = true;
                    // add neighbouring links to reveal array
                    linksToReveal.push(linkId);
                });
                neighbours.nodes.forEach((nodeId) => {
                    // add neighbouring nodes tof foreground map
                    foregroundMap[nodeId] = true;
                });

                for (const itemId of ids) {
                    const nodeInfo = this.keylineData.items.find((n) => n.id === itemId);
                    const {root_node, unique_identifier_id} = nodeInfo?.d ?? {};
                    console.log("nodeInfo", nodeInfo);

                    if (root_node && unique_identifier_id) {
                        const {additionalNodes, additionalLinks} = this.getRootNodeConnections(itemId, ids);

                        for (const nodeId of additionalNodes) {
                            foregroundMap[nodeId] = true;
                        }

                        for (const linkId of additionalLinks) {
                            // foregroundMap[linkId] = true;
                            // linksToReveal.push(linkId);
                            this.additionalComboLinks.push(linkId);
                        }
                    }
                }

                const selectedItems = this.chart.getItem(ids);
                selectedItems.forEach((item) => {
                    // add selected items to foreground map
                    foregroundMap[item.id] = true;
                    if (item.type === "link") {
                        // add only the selected links to the reveal array
                        linksToReveal.push(item.id);
                    }
                });

                // run foreground on underlying links and nodes
                this.chart.foreground((item) => foregroundMap[item.id], {type: "all"});

                // reveal the links
                this.chart.combo().reveal(linksToReveal);

                // background all combolinks
                this.chart
                    .combo()
                    .find(linksToReveal, {parent: "first"})
                    .forEach((id) => {
                        if (id !== null && this.chart.getItem(id).type === "link") {
                            propsToUpdate.push({id, bg: true});
                        }
                    });

                this.chart.setProperties([...propsToUpdate, ...this.additionalComboLinks.map((acl) => ({id: acl, w: 10, bg: false}))]);
            }
        },

        selectCombo(ids) {
            const combos = ids.filter((id) => this.chart.combo().isCombo(id));
            const comboNeighbours = this.chart.graph().neighbours(combos);

            console.log("comboNeighbours", comboNeighbours);
            const nonCombos = ids.filter((id) => !this.chart.combo().isCombo(id));
            const nonComboNeighbours = this.graph.neighbours(nonCombos);
            // Get all the links involved
            const links = comboNeighbours.links.concat(nonComboNeighbours.links);
            // And filter them such that they only include ones linked to an open combo.
            this.chart.combo().reveal(links.filter(this.linkedToOpenCombo));
            if (combos.length) {
                // This allows combos to become selected as there's no 'all' option for chart.foreground
                this.chart.foreground((item) => links.includes(item.id), {type: "link", items: "toplevel"});
            }
            if (nonCombos.length) {
                this.chart.foreground((item) => links.includes(item.id), {type: "link"});
            }
        },

        linkedToOpenCombo(linkId) {
            const link = this.chart.getItem(linkId);
            const node1 = this.chart.combo().find(link.id1);
            const node2 = this.chart.combo().find(link.id2);
            // Filter out non-combo ends of the link
            const linkEndCombos = [node1, node2].filter((id) => this.chart.combo().isCombo(id));
            // Return true only if one of the links is an open combo
            return linkEndCombos.some((comboId) => this.chart.combo().isOpen(comboId));
        },

        getRootNodeConnections(itemId, ids) {
            const additionalNodes = [];
            const additionalLinks = [];

            const rootNodeNeighbours = this.chart.graph().neighbours(itemId);

            const comboNeighbours = this.chart.graph().neighbours(rootNodeNeighbours.nodes.filter((id) => this.chart.combo().isCombo(id)));

            return {
                additionalNodes: additionalNodes.concat(comboNeighbours.nodes),
                additionalLinks: additionalLinks.concat(comboNeighbours.links.filter((l) => !rootNodeNeighbours.links.includes(l))),
            };
        },

        getUniqueCriteriaValues(criteria) {
            const uniqueValues = new Set();

            for (const item of this.keylineData.items) {
                if (criteria in item?.d) uniqueValues.add(item.d[criteria]);
            }

            return Array.from(uniqueValues);
        },

        combineNodesByType() {
            const groupedNodes = {};

            this.keylineData?.items?.forEach((node) => {
                for (const criteria of this.combinedByFilter) {
                    const crItems = this.getUniqueCriteriaValues(criteria.label);
                    const nodeType = crItems?.find((g) => g === node.d?.[criteria.label]);

                    if (nodeType) {
                        const key = node.d?.[criteria.label]?.toUpperCase();

                        if (key) {
                            const id = node.id;

                            if (!groupedNodes[key]) {
                                groupedNodes[key] = {
                                    ids: [],
                                    label: node.d[criteria.label],
                                    style: {
                                        e: 3,
                                        c: "#fff",
                                        fc: "#333",
                                        fs: 20,
                                        bw: 2,
                                        b: "rgb(150,150,150)",
                                    },
                                    openStyle: {
                                        bw: 5,
                                        b: "#c0c0c0",
                                    },
                                    open: false,
                                };
                            }
                            groupedNodes[key].ids.push(id.toString());
                        }
                    }
                }
            });

            return this.cleanSingleOccurrences(groupedNodes);
        },

        cleanSingleOccurrences(groupedNodes) {
            const result = Object.values(groupedNodes).filter((item) => item?.ids?.length > 1);
            return result;
        },

        uncombineNodes() {
            this.updateKeylineData(this.graphData);
        },

        async onToolTypeChange() {
            await this.executeFilter();
            await this.stabilizeGraph();
        },

        async onToolChange(tool) {
            // this.toggleToolData = true;
            if (!tool) return;
            let filters = this.persistToolFilters;

            let id = mapActiveToolToCorrectProductName(tool);

            let activeFilter = [{id: id, label: tool}];
            if (!filters.map((e) => e.label).includes(tool)) filters = filters.concat(activeFilter);
            this.toolType = filters;

            // if (filters.length > 0) {
            //     this.toggleToolData = true;
            // }

            await this.executeFilter();
            await this.stabilizeGraph();
        },

        async executeFilter() {
            this.showLoader = true;

            if (this.toolType.length >= 1) {
                await this.filterNodesByTool();
            } else {
                await this.hideAllToolNodes();
            }

            this.showLoader = false;
        },

        async filterNodesByTool() {
            await this.chart.show(
                this.keylineData?.items?.map((item) => item?.id),
                true
            ); // true -> show links connected to given nodes
        },

        async hideAllToolNodes() {
            await this.chart.hide(this.keylineData?.items?.map((item) => item?.id));
        },

        async onCombinedByFilterChange() {
            this.applyCombined = true;
            await this.combineNodes();
        },

        async emptyToolTypeFilter() {
            this.toolType = [];
            this.graphFilters = [];
            await this.emptySearchTypeFilter();
            await this.executeFilter();
        },

        async emptySearchTypeFilter() {
            this.possibleSearchTypes = [];
            this.graphFilters = [];
            // // this.clearShortestLinkHighlight();
            // let nodesToUpdate = [];
            // for (const node of this.graphNodes) {
            //     node.hidden = true;
            //     nodesToUpdate.push({id: node.id, hi: true});
            // }
            // this.chart.setProperties(nodesToUpdate);
            // await this.executeFilter();
            // // this.resetAllFilters()
        },

        async emptyCombinedByFilter() {
            this.combinedByFilter = [];
            this.uncombineNodes();
        },
    },
    computed: {
        nodesIdsWithUniqueIdentifier() {
            return this.keylineData.items.filter((item) => "unique_identifier_id" in item?.d).map((item) => item.id);
        },
        tools() {
            const tools = [];

            if (!this.keylineData?.items?.length) return tools;

            for (const item of this.keylineData?.items) {
                if (!item.d?.source) continue;
                if (item.d?.source === "input") continue; // Only for the current PoC

                if (!tools.includes(item.d.source) && item.d.source !== undefined && item.d.source !== "caseData") {
                    tools.push(item.d.source);
                }
            }

            return tools.map((t) => {
                // id = removeHyphenAndTitleCase(id);
                return {id: toolsMapper[t] ?? t, label: t};
            });
        },
        toolsList() {
            return [{group: "Tools", categories: this.tools}];
        },
        isToolFilterDisabled() {
            return false;
            // return !this.toggleToolData;
        },
        iconMap() {
            return {
                risk: require("@/assets/icons/graph/risk.png"),
                unknown: require("@/assets/icons/graph/unknown.png"),
                country: require("@/assets/icons/graph/country.png"),
                "country-green": require("@/assets/icons/graph/country-green.png"),
                person: require("@/assets/icons/graph/person.png"),
                "person-green": require("@/assets/icons/graph/person-green.png"),
                company: require("@/assets/icons/graph/company.png"),
                "company-green": require("@/assets/icons/graph/company-green.png"),
                internet: require("@/assets/icons/graph/internet.png"),
                date: require("@/assets/icons/graph/date.png"),
                "date-green": require("@/assets/icons/graph/date-green.png"),
                address: require("@/assets/icons/graph/address.png"),
                "address-green": require("@/assets/icons/graph/address-green.png"),
            };
        },
    },
};
</script>
<style src="./klGraphNew.scss" scoped lang="scss"></style>
<style scoped lang="scss">
.unselectable {
    -webkit-user-select: none; /* Safari */
    user-select: none;
}

.active-btn {
    border: 1px solid var(--brand-color);
    background: var(--brand-color);
    color: #ffff !important;
}

html {
    box-sizing: border-box;
}

.collapse_btn {
    display: flex;
    width: 43px;
    height: 38px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-top: 0.5px solid #e9e9ef;
    background: #fff;
}

.btn-color {
    border-radius: 2px;
    background: var(--brand-color);
}

.bg-drop {
    font-size: 16px;
    color: cornflowerblue;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
}
.filter-item {
    margin-bottom: 10px;
}

.dot-mark {
    position: absolute;
    top: 1px;
    left: 37px;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
}
.dot-mark-depth {
    position: absolute;
    top: 36px;
    left: 37px;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
}

.dot-mark-combine {
    position: absolute;
    top: 78px;
    left: 37px;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
}

.btn-wide {
    width: 70%;
}

.klchart {
    width: 100% !important;
    height: 1000px;
    overflow-y: hidden;
    font-family: "Font Awesome 5 free" !important;
    // font-family: 'FontAwesome' !important;
}
::v-deep {
    .offcanvasFilter .offcanvas-content {
        position: fixed;
        top: 0%;
        width: 100vw;
        height: 100%;
        background-color: #fff;
        z-index: 9998;
        transition: box-shadow 0.3s ease;
        border-radius: 12px 0px 0px 12px;
        border: 0px solid var(--Input, #b9b9b9);
        box-shadow: -1px 0px 42px 0px rgba(0, 0, 0, 0.08);
    }
}
</style>
